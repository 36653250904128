import { useState } from 'react';
import { Flex } from '@clariness/flex';
import { Tag } from '@clariness/clarikit-react';
import { referralStatusMapper } from 'utils/format';
import { Button } from '@clariness/button';
import { Store } from 'store';
import { useIntl } from 'react-intl';

type Tag = {
  id: string;
  name: string;
  type: 'status' | 'study';
};

export const ReferralTableFilterTags = () => {
  const intl = useIntl();
  const {
    filterTags,
    setFilterTags,
    localFilterOptions,
    setLocalFilterOptions,
    isFilterCleared,
    setIsFilterCleared,
  } = Store.useHomePageStore();

  const [showMore, setShowMore] = useState<boolean>(false);

  const allTags: Array<Tag> = [];
  localFilterOptions?.statuses?.map((status) =>
    allTags.push({
      type: 'status',
      name: status,
      id: status,
    })
  );
  filterTags?.selectedStudies?.map((study) =>
    allTags.push({ ...study, type: 'study' })
  );

  const tagList = !showMore ? allTags?.slice(0, 3) : allTags;

  const onCloseTag = (tag: Tag): void => {
    if (tag.type === 'study') {
      const studyIds = localFilterOptions?.selectedStudyIds?.filter(
        (id) => id !== tag.id
      );
      setLocalFilterOptions({
        ...localFilterOptions,
        selectedStudyIds: studyIds,
      });
      const selectedStudies = filterTags?.selectedStudies?.filter(
        (study) => study.id !== tag.id
      );
      setFilterTags({ ...filterTags, selectedStudies });
    } else {
      const statuses = localFilterOptions?.statuses?.filter(
        (status) => status !== tag.id
      );
      setLocalFilterOptions({ ...localFilterOptions, statuses });
    }

    if (!isFilterCleared) {
      setIsFilterCleared(true);
    }
  };

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        columnGap: '18px',
        rowGap: '10px',
        fontFamily: 'Noto Sans',
      }}
      data-test-id="referral-table-filter-tags"
    >
      {tagList.map((tag) => (
        <Tag 
          key={tag.id} 
          onClose={() => onCloseTag(tag)}
          color="green"
          label={
            tag.type === 'study' ? (
              tag.name
            ) : (
              `${intl.formatMessage({
                id: "is.referrals_table.status_column",
                defaultMessage: 'Status'
              })}- ${referralStatusMapper[tag.name].descriptionRaw.defaultMessage}`
            )
          }
        />
      ))}
      {allTags?.length > 3 && (
        <Button
          variant="base"
          onClick={() => setShowMore(!showMore)}
          sx={{
            color: '#398717',
            fontWeight: 'bold',
          }}
        >
          {!showMore ? `+${allTags?.length - 3} More` : 'Hide'}
        </Button>
      )}
    </Flex>
  );
};
