import React from 'react';
import { AccountPageLayout } from 'pages/Account/components';

import { Services } from 'services';
import { Store } from 'store';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { Text } from '@clariness/clarikit-react';

export const MySites = () => {
  const { user } = Store.useUser();

  const { sites } = Services.InvestigatorSites.useGetInvestigatorSites(
    user?.id || ''
  );
  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage id="is.sites_link" defaultMessage="My Sites" />
      </AccountPageLayout.Heading>
      <Flex
        sx={{
          gap: '20px',
          marginBottom: '20px',
          flexDirection: 'column',
        }}
      >
        <AccountPageLayout.Paragraph>
          <FormattedMessage
            id="is.investigator.sites_list"
            defaultMessage="List of the sites you are assigned to"
          />
        </AccountPageLayout.Paragraph>
      </Flex>
      <Flex sx={{ gap: '20px', flexDirection: 'column', width: '100%' }}>
        {' '}
        {sites?.map((site) => {
          return (
            <>
              <Flex
                sx={{
                  marginBottom: '10px',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Box>
                  <Text
                    as="h4"
                    variant="headingXs"
                    color="color-gray-variant-1"
                  >
                    {site.name}
                  </Text>
                </Box>
              </Flex>
              <Flex sx={{ gap: '15px', flexDirection: 'column' }}>
                <Text as="p">{site.street}</Text>
                <Text as="p">
                  {site.zipCode} {site.city}{' '}
                </Text>
                <Text as="p">{site.country}</Text>
              </Flex>
            </>
          );
        })}
      </Flex>
    </AccountPageLayout>
  );
};
