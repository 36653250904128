import React from 'react';
import { ArrowIcon } from '@clariness/icon';
import { Flex } from '@clariness/flex';
import { Sidebar } from 'components/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import * as ReactRouterDom from 'react-router-dom';
import { breakpoints } from 'utils/breakpoints';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';
import {
  AccountDetails,
  AccountLanguage,
  AccountNotification,
  MySites,
} from './components';

export const Account = () => {
  const { redirect } = useRedirect();
  const { user } = Store.useUser();

  return (
    <Flex sx={{ width: '100%' }}>
      <Sidebar>
        <Sidebar.NavButton
          icon={<ArrowIcon direction="left" />}
          onClick={() => redirect(Routing.HOME.getPath())}
          data-test-id="home-nav-button"
        >
          <FormattedMessage
            id="is.account.side_bar.back_to_patient"
            defaultMessage="Back to patient view"
          />
        </Sidebar.NavButton>

        <Sidebar.NavLink
          to={Routing.ACCOUNT_DETAILS.getPath()}
          data-test-id="account-details-nav-button"
        >
          <FormattedMessage
            id="is.account.side_bar.account_details"
            defaultMessage="Account details"
          />
        </Sidebar.NavLink>

        <Sidebar.NavLink
          to={Routing.MY_SITES.getPath()}
          data-test-id="mysites-nav-button"
        >
          <FormattedMessage id="is.sites_link" defaultMessage="My Sites" />
        </Sidebar.NavLink>

        <Sidebar.NavLink
          to={Routing.ACCOUNT_LANGUAGE.getPath()}
          data-test-id="language-nav-button"
        >
          <FormattedMessage
            id="is.account.side_bar.language"
            defaultMessage="Language"
          />
        </Sidebar.NavLink>
        <Sidebar.NavLink
          onClick={() => {
            captureEventV2(
              Events.InvestigatorService.InvestigatorViewedAccountNotificationPage(
                { InvestigatorID: user?.id ?? '' }
              )
            );
          }}
          to={Routing.ACCOUNT_NOTIFICATION.getPath()}
          data-test-id="notifications-nav-button"
        >
          <FormattedMessage
            id="is.account_notification.heading"
            defaultMessage="Notifications"
          />
        </Sidebar.NavLink>
      </Sidebar>

      <Flex
        sx={{
          flex: 1,
          justifyContent: 'center',
          py: breakpoints({ _: '35px', sm: '90px' }),
        }}
      >
        <ReactRouterDom.Switch>
          <ReactRouterDom.Route
            path={Routing.ACCOUNT_DETAILS.getPath()}
            component={AccountDetails}
          />

          <ReactRouterDom.Route
            path={Routing.ACCOUNT_LANGUAGE.getPath()}
            component={AccountLanguage}
          />

          <ReactRouterDom.Route
            path={Routing.MY_SITES.getPath()}
            component={MySites}
          />

          <ReactRouterDom.Route
            path={Routing.ACCOUNT_NOTIFICATION.getPath()}
            component={AccountNotification}
          />

          <ReactRouterDom.Redirect to={Routing.ACCOUNT_DETAILS.getPath()} />
        </ReactRouterDom.Switch>
      </Flex>
    </Flex>
  );
};
