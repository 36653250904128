import { useEffect, useState } from 'react';
import { Store } from 'store';

import { LabeledContent, LabeledRow } from 'components/content';
import { useIntl, FormattedMessage } from 'react-intl';
import { Services } from 'services';
import { ShouldRender } from 'components/ShouldRender';
import { ProtocolInfoDto, ReferralDto, ProtocolDto } from 'common/api';
import { LabeledRadioButton } from '@clariness/labeled-radio-button';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/clarikit-react';
import { Button } from '@clariness/button';
import { Events } from '@clariness/tracking-event-runtime';
import { captureEventV2 } from 'utils/events/captureEvent';
import { DownloadIcon } from '@clariness/icon';
import { SponsorID } from './components/SponsorID';
import { Protocol801 } from './components/Protocol801';

type Props = {
  referral: ReferralDto;
  Protocol801Participation: (value: any) => void;
  refetch: () => void
};

export const StudyDetails = ({ referral, Protocol801Participation, refetch }: Props) => {
  const intl = useIntl();

  const { getPdf, isLoading } = Services.Referrals.useGetPdf();
  const { user } = Store.useUser();

  const { protocolsData } = Services.Referrals.useGetReferralProtocol(referral?.id || '');
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolInfoDto>();
  const { openModal, closeModal } = Store.useModal();

  useEffect(() => {
    if (protocolsData?.selectedProtocol) {
      setSelectedProtocol(protocolsData?.selectedProtocol);
    }
  }, [protocolsData]);

  const handleChangeProtocol = (protocol: ProtocolInfoDto) => {
    setSelectedProtocol(protocol);
    closeModal();
    if (referral?.id) {
      openModal({
        modalType: 'ProtocolConfirmation',
        modalProps: {
          referral,
          protocol: { ...protocol },
        },
      });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <LabeledContent
        label={intl.formatMessage({
          id: 'is.patient_details_modal.basic_details.study',
          defaultMessage: 'Study',
        })}
      >
        <LabeledContent.Table>
          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.study',
              defaultMessage: 'Study',
            })}
          >
            {referral?.study.name}
          </LabeledRow>
          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.Site',
              defaultMessage: 'Site',
            })}
          >
            {referral?.site?.name}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.sponsor_patient_id',
              defaultMessage: 'Sponsor Patient ID',
            })}
          >
            <SponsorID referral={referral} />
          </LabeledRow>

          <ShouldRender when={referral?.study?.trackingId === 1605}>
            <LabeledRow
              label={intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.protocol_801',
                defaultMessage: 'Protocol 801 if applicable',
              })}
            >
              <Protocol801
                referral={referral}
                Protocol801Participation={Protocol801Participation}
                refetch={refetch}
              />
            </LabeledRow>
          </ShouldRender>

          <ShouldRender when={
            protocolsData?.protocolList?.length !== 0 &&
            protocolsData?.protocolsCount !== 0
          }
          >
            <LabeledRow
              label={intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.available_protocols',
                defaultMessage: 'Available Protocols',
              })}
            >
              {protocolsData?.protocolList?.map((protocol: ProtocolInfoDto) => (
                <Box
                  key={protocol.id}
                  sx={{
                    marginBottom: '8px !important',
                  }}
                >
                  <LabeledRadioButton
                    label={`${protocol.internalId || '...'} - ${protocol.name}`}
                    id={protocol.id.toString()}
                    value={protocol.id}
                    checked={protocol.id === selectedProtocol?.id}
                    onChange={() => handleChangeProtocol(protocol)}
                  />
                </Box>
              ))}
            </LabeledRow>
          </ShouldRender>
        </LabeledContent.Table>
      </LabeledContent>

      <ShouldRender when={Boolean(referral?.patient?.qualifiedStudyProtocols
        && referral?.patient?.qualifiedStudyProtocols?.length > 0)}
      >
        <Flex sx={{
          flexDirection: 'column',
          gap: '2',
        }}
        >
          <Text as='p' fontWeight="bold">
            <FormattedMessage
              id="is.qualified.study.protocols.message"
              defaultMessage="Based on screener answers eligible for :"
            />
          </Text>
          <Text as="p">
            {referral?.patient?.qualifiedStudyProtocols?.map((protocol: ProtocolDto) => (
              ` ${protocol.name}`
            )).join(',')}
          </Text>
        </Flex>
      </ShouldRender>

      <Button
        variant="tertiary"
        size="large"
        loading={isLoading}
        onClick={() => {
          captureEventV2(Events.InvestigatorService.InvestigatorExportedPatientProfile({ InvestigatorID: user?.id ?? '' }));
          getPdf({
            id: referral.id,
            firstName: referral.patient.firstName,
            lastName: referral.patient.lastName,
          });
        }}
        icon={<DownloadIcon />}
        marginTop="44px"
      >
        <FormattedMessage
          id="is.patient_details_modal.footer.export_pdf_button"
          defaultMessage="Export as PDF"
        />
      </Button>
    </Box>
  );
};
