import React, { PropsWithChildren } from 'react';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { Image } from '@clariness/image';
import { Text } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';
import genericErrorSrc from 'assets/images/generic-error.png';
import { FormattedMessage } from 'react-intl';

type ServiceErrorProps = {
  onRetry: () => void;
};

export const ServiceError = ({
  children,
  onRetry,
}: PropsWithChildren<ServiceErrorProps>) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '28px',
        m: 'auto',
      }}
    >
      <Image
        width="213px"
        src={genericErrorSrc}
        alt="Something went wrong illustration"
      />
      <Box
        sx={{
          fontSize: '21px',
        }}
      >
        <Text as="p" color="color-gray-variant-2">
          {children}
        </Text>
      </Box>
      <Button onClick={onRetry}>
        <FormattedMessage
          id="is.generic_error.try_again_button"
          defaultMessage="Try again"
        />
      </Button>
    </Flex>
  );
};
