import React from 'react';
import { Flex } from '@clariness/flex';
import { Image } from '@clariness/image';
import { Text } from '@clariness/clarikit-react';
import tableNoResultsSrc from 'assets/images/table-no-results.png';
import { ShouldRender } from 'components/ShouldRender';
import { useTableContext } from 'components/Table';

type TableNoResultsProps = {
  title: string;
  description?: string;
};

export const TableNoResults = ({ title, description }: TableNoResultsProps) => {
  const { isLoading } = useTableContext();

  return (
    <ShouldRender when={!isLoading}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          m: 'auto',
          justifyContent: 'center',
          gap: '40px',
          color: '#707070',
        }}
      >
        <Image
          width="142px"
          src={tableNoResultsSrc}
          alt="Empty notebooks illustrating that no results have been found"
        />
        <Flex sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8px',
        }}>
          <Text as="p" variant='bodyLg'>{title}</Text>

          <ShouldRender when={Boolean(description)}>
            <Text as="p" variant='bodyLg'>{description}</Text>
          </ShouldRender>
        </Flex>
      </Flex>
    </ShouldRender>
  );
};
