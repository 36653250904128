import { useState, useEffect } from 'react';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { Services } from 'services';
import { ReferralDto } from 'common/api';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@clariness/clarikit-react';

type Props = {
  referral?: ReferralDto;
};

export const SponsorID = ({ referral }: Props) => {
  const [sponsorPatientID, setSponsorPatientID] = useState<string>();
  const [isSponsorPatientIDEditable, setIsSponsorPatientIDEditable] =
    useState<boolean>(true);
  const isDisbaled =
    ReferralStatus[referral?.status as ReferralStatusType] !==
      ReferralStatus.CONSENTED &&
    ReferralStatus[referral?.status as ReferralStatusType] !==
      ReferralStatus.SCREENING_FAILURE &&
    ReferralStatus[referral?.status as ReferralStatusType] !==
      ReferralStatus.RANDOMIZED;

  const { updateReferralSponsorPatientIdAPI } =
    Services.Referrals.useUpdateReferralSponsorPatientId();

  useEffect(() => {
    setSponsorPatientID(referral?.sponsorPatientID);
    if (referral?.sponsorPatientID) {
      setIsSponsorPatientIDEditable(false);
    }
  }, [referral]);

  const saveSponsorPatientID = () => {
    if (referral?.id && sponsorPatientID !== referral.sponsorPatientID) {
      updateReferralSponsorPatientIdAPI({
        referralId: referral.id,
        sponsorPatientID: sponsorPatientID || '',
      });
    }
    setIsSponsorPatientIDEditable(false);
  };

  const disableCheck = () => {
    if (isDisbaled) {
      return true;
    }
    return !isSponsorPatientIDEditable;
  };
  return (
    <Flex flexDirection="column" alignItems="flex-end">
      <TextField
        value={sponsorPatientID}
        disabled={disableCheck()}
        onChange={(val) => setSponsorPatientID(val)}
        autoComplete='off'
        label=''
      />
      {referral?.sponsorPatientID && !isSponsorPatientIDEditable ? (
        <Button
          variant="tertiary"
          size="medium"
          onClick={() => setIsSponsorPatientIDEditable(true)}
          disabled={isDisbaled}
        >
          <FormattedMessage id="is.edit" defaultMessage="Edit" />
        </Button>
      ) : (
        <Button
          variant="tertiary"
          size="medium"
          disabled={isDisbaled}
          onClick={saveSponsorPatientID}
        >
          <FormattedMessage id="is.add" defaultMessage="Add" />
        </Button>
      )}
    </Flex>
  );
};
