import React, { ReactNode } from 'react';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/clarikit-react';

type UpdateStatusLayoutProps = {
  heading: ReactNode | string;
};

export const UpdateStatusLayout: React.FC<UpdateStatusLayoutProps> = ({
  heading,
  children,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '25px',
        width: '100%',
      }}
    >
      <Text as="p" fontWeight="bold">
        {heading}
      </Text>

      {children}
    </Flex>
  );
};
