import React from 'react';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from 'components/links';
import { Divider } from 'components/Divider';
import { ExternalLinks } from 'global/externalLinks';

export const Footer: React.FC = () => {
  return (
    <Flex
      sx={{
        justifyContent: 'flex-end',
        gap: '15px',
        mt: 'auto',
        backgroundColor: 'hgl6',
        p: '10px 50px',
        fontSize: '12px',
      }}
    >
      <Box
        sx={{
          color: '#535A5F',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExternalLink variant="small">
          <FormattedMessage
            id="is.footer.text"
            defaultMessage="Copyright CLARINESS © 2021. All rights reserved."
          />
        
        </ExternalLink>
      </Box>

      <ExternalLink variant="small" to={ExternalLinks.PRIVACY_POLICY}>
        <FormattedMessage
          id="is.footer.link.privacy_policy"
          defaultMessage="Privacy policy"
        />
      </ExternalLink>

      <Divider />

      <ExternalLink variant="small" to={ExternalLinks.IMPRINT}>
        <FormattedMessage
          id="is.footer.link.imprint"
          defaultMessage="Imprint"
        />
      </ExternalLink>

      <Divider />

      <ExternalLink variant="small" to={ExternalLinks.TERMS_OF_USE}>
        <FormattedMessage
          id="is.footer.link.terms_of_service"
          defaultMessage="Terms of Service"
        />
      </ExternalLink>
    </Flex>
  );
};
