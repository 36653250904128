import React, { PropsWithChildren } from 'react';
import { Text } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';

export const AuthPageLayoutParagraph = ({
  children,
}: PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={{
        mb: '34px',
      }}
    >
      <Text as="p" fontWeight="bold">
        {children}
      </Text>
    </Box>
  );
};
