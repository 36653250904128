import React, { useEffect } from 'react';
import { Services } from 'services';
import { AuthPageLayout } from 'components/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { PasswordField } from '@clariness/password-field';
import { Link } from 'components/links';
import { Schemas, useFormValidation } from 'utils/validation';
import { Routing } from 'global/routing';
import { TextField } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormValidation();
  const intl = useIntl();
  const { login, isLoading } = Services.Auth.useLogin();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  const { onChange, name } = register('email');

  return (
    <AuthPageLayout>
      <AuthPageLayout.Logo />

      <AuthPageLayout.Heading>
        <FormattedMessage
          id="is.login.heading"
          defaultMessage="Login to ClinLife"
        />
      </AuthPageLayout.Heading>

      <AuthPageLayout.Form
        onSubmit={handleSubmit((data) => login(data.email, data.password))}
      >
        <Box
          sx={{
            width: '100%'
          }}
        >
          <TextField
            requiredIndicator={true}
            placeholder={intl.formatMessage({
              id: 'login.email.placeholder',
              defaultMessage: 'Email Address',
            })}
            {...register('email', Schemas.email(intl))}
            value={watch('email')}
            onChange={(value) => {
              onChange({ target: { name, value } });
            }}
            error={errors?.email?.message}
            type="email"
            data-test-id="IS-login-email-input"
            autoComplete="on"
            label='email'
            labelHidden
          />
        </Box>

        <PasswordField
          required
          placeholder={intl.formatMessage({
            id: 'login.password.placeholder',
            defaultMessage: 'Password',
          })}
          {...register('password', Schemas.password(intl, true))}
          error={errors?.password?.message}
          data-test-id="IS-login-password-input"
        />

        <Button
          type="submit"
          loading={isLoading}
          data-test-id="IS-login-button"
        >
          <FormattedMessage id="is.login.button" defaultMessage="Login" />
        </Button>
        <Link
          to={Routing.FORGOT_PASSWORD.getPath()}
          data-test-id="IS-login-forgot-passwrod-link"
        >
          <FormattedMessage
            id="is.login.forgot_password_link"
            defaultMessage="Forgotten password? Click here"
          />
        </Link>
      </AuthPageLayout.Form>

      <AuthPageLayout.InfoLinks />
      <AuthPageLayout.Footer />
    </AuthPageLayout>
  );
};
