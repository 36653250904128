import { useIntl, FormattedMessage } from 'react-intl';
import { Text } from '@clariness/clarikit-react';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { Tooltip } from '@clariness/tooltip';
import { ThemeUIStyleObject } from 'theme-ui';
import { isDateInPast } from './formatDate';
import { themes } from '@clariness/clarikit-tokens';

const { color } = themes.light;
type ColorTokenName = keyof typeof themes.light.color;

export const referralStatusMapper = {
  [ReferralStatus.NEW_REFERRAL]: {
    description: (
      <FormattedMessage
        id="is.referral_status.new_referral"
        defaultMessage="New"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.new_referral',
      defaultMessage: 'New',
    },
    color: '#55ACA4',
    backgroundColor: '',
    message: {
      id: 'is.referral_status.new_referral.description',
      defaultMessage: 'A new patient has been referred to your site',
    },
  },
  [ReferralStatus.ATTEMPTING_TO_CONTACT]: {
    description: (
      <FormattedMessage
        id="is.referral_status.attempting_to_contact"
        defaultMessage="Attempting contact"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.attempting_to_contact',
      defaultMessage: 'Attempting contact',
    },
    color: '#BD356E',
    message: {
      id: 'is.update_referral_status.attempting_to_contact.description',
      defaultMessage: 'Tried contacting the patient but were unsuccessful',
    },
  },
  [ReferralStatus.CONTACTED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted"
        defaultMessage="Contacted"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted',
      defaultMessage: 'Contacted',
    },
    color: '#FBD13D',
    message: {
      id: 'is.update_referral_status.contacted.description',
      defaultMessage:
        'The patient was reached but further information is pending to schedule an appointment',
    },
  },

  [ReferralStatus.CONTACTED__CALLBACK]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_callback"
        defaultMessage="Callback"
      />
    ),

    descriptionRaw: {
      id: 'is.referral_status.contacted_callback',
      defaultMessage: 'Callback',
    },
    color: '#D41717A6',
    message: {
      id: 'is.update_referral_status.contacted_callback.description',
      defaultMessage: 'The patient has requested a callback at another time',
    },
  },

  [ReferralStatus.CONTACTED__PATIENT_AVAILABILITY]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_patient_availability"
        defaultMessage="Patient availability"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted_patient_availability',
      defaultMessage: 'Patient availability',
    },
    color: '#D41717A6',
    message: {
      id: 'is.referral_status.contacted_patient_availability.description',
      defaultMessage:
        'Waiting for the patient to share their availability to schedule an appointment',
    },
  },

  [ReferralStatus.CONTACTED__MEDICAL_RECORDS]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_waiting_for_medical_records"
        defaultMessage="Medical records"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted_waiting_for_medical_records',
      defaultMessage: 'Medical records',
    },
    color: '#D41717A6',
    message: {
      id: 'is.referral_status.contacted_waiting_for_medical_records.description',
      defaultMessage: 'Waiting for the patient to share medical records ',
    },
  },

  [ReferralStatus.CONTACTED__WASHOUT_PERIOD]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_washout_period"
        defaultMessage="Washout period"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted_washout_period',
      defaultMessage: 'Washout period',
    },
    color: '#D41717A6',
    message: {
      id: 'is.referral_status.contacted_washout_period.description',
      defaultMessage: 'Waiting for the patient to complete the washout period',
    },
  },

  [ReferralStatus.APPOINTMENT_SCHEDULED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_appointment_scheduled"
        defaultMessage="Scheduled"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted_appointment_scheduled',
      defaultMessage: 'Scheduled',
    },
    color: '#FBD13D',
    message: {
      id: 'is.update_referral_status.contacted_appointment_scheduled.description',
      defaultMessage: 'The patient has an appointment scheduled at the site',
    },
  },

  APPOINTMENT_SCHEDULED_IN_THE_PAST: {
    description: (
      <FormattedMessage
        id="is.referral_status.contacted_appointment_scheduled"
        defaultMessage="Scheduled"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.contacted_appointment_scheduled',
      defaultMessage: 'Scheduled',
    },
    textColor: 'color-white-variant-1',
    backgroundColor: 'red-alert',
    color: 'red-alert',
    message: {
      id: 'is.update_referral_status.contacted_appointment_scheduled.description',
      defaultMessage: 'The patient has an appointment scheduled at the site',
    },
  },

  [ReferralStatus.CONSENTED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.consented"
        defaultMessage="Consented"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.consented',
      defaultMessage: 'Consented',
    },
    color: '#76BC58',
    message: {
      id: 'is.referral_status.consented.description',
      defaultMessage: 'The patient has signed ICF and is in screening',
    },
  },
  [ReferralStatus.RANDOMIZED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.icf_signed_randomized"
        defaultMessage="Randomized"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.icf_signed_randomized',
      defaultMessage: 'Randomized',
    },
    textColor: 'color-white-variant-1',
    backgroundColor: '#296510',
    color: '#296510',
    message: {
      id: 'is.update_referral_status.icf_signed_randomized.description',
      defaultMessage: 'The patient has been accepted into the clinical study',
    },
  },

  [ReferralStatus.SCREENING_FAILURE]: {
    description: (
      <FormattedMessage
        id="is.referral_status.icf_signed_screening_failure"
        defaultMessage="Screening Failure"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.icf_signed_screening_failure',
      defaultMessage: 'Screening Failure',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.referral_status.screening_failure.description',
      defaultMessage: `The patient did not meet one or more criteria after the screening visit.
       Results are formally confirmed by the site.`,
    },
  },

  [ReferralStatus.WAITING_FOR_INFORMATION]: {
    description: (
      <FormattedMessage
        id="is.referral_status.waiting_information"
        defaultMessage="Waiting for information"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.waiting_information',
      defaultMessage: 'Waiting for information',
    },
    color: '#707070',
    message: {
      id: 'is.referral_status.waiting_information.description',
      defaultMessage:
        'The patient visit was completed and waiting for the patient to share information.',
    },
  },

  [ReferralStatus.WAITING_FOR_INFORMATION__MEDICAL_RECORD]: {
    description: (
      <FormattedMessage
        id="is.referral_status.waiting_medical_records"
        defaultMessage="Medical Records"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.waiting_medical_records',
      defaultMessage: 'Medical Records',
    },
    color: 'primary0',
    message: {
      id: 'is.referral_status.waiting_medical_records.description',
      defaultMessage: 'Waiting for the patient to share medical records',
    },
  },

  [ReferralStatus.WAITING_FOR_INFORMATION__WAITING_FOR_ICF]: {
    description: (
      <FormattedMessage
        id="is.referral_status.waiting_for_icf_signing"
        defaultMessage="Waiting for ICF"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.waiting_for_icf_signing',
      defaultMessage: 'Waiting for ICF',
    },
    color: 'primary0',
    message: {
      id: 'is.referral_status.waiting_for_icf_signing.description',
      defaultMessage: 'Waiting for the patient to sign ICF',
    },
  },

  [ReferralStatus.WAITING_FOR_INFORMATION__WASHOUT_PERIOD]: {
    description: (
      <FormattedMessage
        id="is.referral_status.waiting_washout_period"
        defaultMessage="Washout period"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.waiting_washout_period',
      defaultMessage: 'Washout period',
    },
    color: 'primary0',
    message: {
      id: 'is.referral_status.waiting_washout_period.description',
      defaultMessage: 'Waiting for the patient to complete the washout period',
    },
  },

  [ReferralStatus.DROPPED__NO_SHOW]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_no_show"
        defaultMessage="No show"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_no_show',
      defaultMessage: 'No show',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.referral_status.dropped_no_show.description',
      defaultMessage: 'The patient missed their scheduled appointment',
    },
  },

  [ReferralStatus.DROPPED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped"
        defaultMessage="Dropped"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped',
      defaultMessage: 'Dropped',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.referral_status.dropped.description',
      defaultMessage:
        'The patient will not be considered for the clinical study',
    },
  },

  [ReferralStatus.DROPPED__UNABLE_TO_REACH]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_unable_to_reach"
        defaultMessage="Unable to reach"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_unable_to_reach',
      defaultMessage: 'Unable to reach',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.update_referral_status.dropped_unable_to_reach.description',
      defaultMessage:
        'The patient could not be reached after several contact attempts',
    },
  },

  [ReferralStatus.DROPPED_BY_CC__TOO_FAR_AWAY]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_too_far_away.description"
        defaultMessage="Too far away"
      />
    ),
    textColor: 'color-white-variant-1',
    color: '#FFDEDE',
    backgroundColor: '#FFDEDE',
    descriptionRaw: {
      id: 'is.update_referral_status.dropped_too_far_away.description',
      defaultMessage: 'Too far away',
    },
  },

  [ReferralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_failed_in_exclusion_criteria"
        defaultMessage="Failed criteria"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_failed_in_exclusion_criteria',
      defaultMessage: 'Failed criteria',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.update_referral_status.dropped_failed_in_exclusion_criteria.description',
      defaultMessage: 'The patient doesn’t meet the study criteria',
    },
  },
  [ReferralStatus.DROPPED__NOT_INTERESTED]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_not_interested"
        defaultMessage="Not interested"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_not_interested',
      defaultMessage: 'Not interested',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.update_referral_status.dropped_not_interested.description',
      defaultMessage:
        'The patient is not interested in participating in the clinical study',
    },
  },
  [ReferralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_bad_or_duplicate_profile"
        defaultMessage="Bad profile"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_bad_or_duplicate_profile',
      defaultMessage: 'Bad profile',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.update_referral_status.dropped_bad_or_duplicate_profile.description',
      defaultMessage:
        'Patient data can’t be used or it already exists in your database',
    },
  },

  [ReferralStatus.DROPPED__TOO_FAR_AWAY]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.dropped_too_far_away.title"
        defaultMessage="Too far away"
      />
    ),
    descriptionRaw: {
      id: 'is.update_referral_status.dropped_too_far_away.title',
      defaultMessage: 'Too far away',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.update_referral_status.dropped_too_far_away.description',
      defaultMessage: 'The patient is too far away from the site',
    },
  },

  [ReferralStatus.DROPPED__OTHER]: {
    description: (
      <FormattedMessage
        id="is.referral_status.dropped_other"
        defaultMessage="Other"
      />
    ),
    descriptionRaw: {
      id: 'is.referral_status.dropped_other',
      defaultMessage: 'Other',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: 'text1',
    message: {
      id: 'is.referral_status.dropped_other_reason.description',
      defaultMessage:
        'Select for any other reason that has not been mentioned previously',
    },
  },
  [ReferralStatus.IN_CALL_CENTER]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.in_call_center"
        defaultMessage="In call center."
      />
    ),
    descriptionRaw: {
      id: 'is.update_referral_status.in_call_center',
      defaultMessage: 'In call center.',
    },
    color: '#707070',
  },
  [ReferralStatus.ON_HOLD_IN_CALL_CENTER]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.on_hold_by_cc"
        defaultMessage="On hold by call center."
      />
    ),
    descriptionRaw: {
      id: 'is.update_referral_status.on_hold_by_cc',
      defaultMessage: 'On hold by call center.',
    },
    color: '#707070',
  },

  [ReferralStatus.ON_HOLD_IN_CALL_CENTER]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.on_hold_by_cc"
        defaultMessage="On hold by call center."
      />
    ),
    descriptionRaw: {
      id: 'is.update_referral_status.on_hold_by_cc',
      defaultMessage: 'On hold by call center.',
    },
    color: '#707070',
  },

  [ReferralStatus.RELOCATION]: {
    description: (
      <FormattedMessage
        id="is.update_referral_status.relocation"
        defaultMessage="Relocation"
      />
    ),
    descriptionRaw: {
      id: 'is.update_referral_status.relocation',
      defaultMessage: 'Relocation',
    },
    textColor: 'color-white-variant-1',
    color: 'text-inverted',
    backgroundColor: '#E26118',
  },
};

/**
 * @return referralStatus UI with a colored circle next to the a descriptive name of the status
 */
export function FormatReferralStatus(
  status: ReferralStatusType,
  appointmentDateTime?: string
) {
  let referralStatus = referralStatusMapper[status];

  const intl = useIntl();

  const statusDesc =
    referralStatus?.descriptionRaw &&
    intl.formatMessage({
      id: referralStatus.descriptionRaw.id,
      defaultMessage: referralStatus.descriptionRaw.defaultMessage,
    });

  const statusMessage =
    referralStatus?.message &&
    intl.formatMessage({
      id: referralStatus.message.id,
      defaultMessage: referralStatus.message.defaultMessage,
    });

  const toolbarBoxStyle: ThemeUIStyleObject = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
  };

  if (
    appointmentDateTime &&
    ReferralStatus.APPOINTMENT_SCHEDULED &&
    isDateInPast(appointmentDateTime)
  ) {
    referralStatus = referralStatusMapper.APPOINTMENT_SCHEDULED_IN_THE_PAST;
  }

  if (referralStatus) {
    return (
      <Flex
        sx={{
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Flex
          sx={{
            border: '2px solid',
            borderRadius: '4px',
            padding: '8px 16px',
            borderColor: referralStatus.color,
            backgroundColor: referralStatus.backgroundColor,
            flexShrink: 0,
            width: '164px',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Text
              as="p"
              variant="bodyMd"
              fontWeight="bold"
              color={referralStatus?.textColor as ColorTokenName}
            >
              <Tooltip
                trigger={
                  <Box sx={toolbarBoxStyle}>
                    {referralStatus.descriptionRaw.defaultMessage.toLowerCase() ===
                    'scheduled'
                      ? ` ${new Date(appointmentDateTime as string)
                          .getDate()
                          .toLocaleString('en-us', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}/${(new Date(appointmentDateTime as string).getMonth() + 1).toLocaleString('en-us', { minimumIntegerDigits: 2, useGrouping: false })} ${statusDesc.toUpperCase()} `
                      : `${statusDesc.toString().toUpperCase().substring(0, 22)}`}
                  </Box>
                }
              >
                {statusMessage?.toString() ||
                  statusDesc?.toString().toUpperCase()}
              </Tooltip>
            </Text>
          </Box>
        </Flex>
      </Flex>
    );
  }

  return null;
}
