import React from 'react';
import { Text } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';

export const AuthPageLayoutHeading: React.FC = ({ children }) => {
  return (
    <Box sx={{ mb: '36px' }}>
      <Text as="h3" variant="headingSm">
        {children}
      </Text>
    </Box>
  );
};
