import React, { useEffect } from 'react';
import { AuthPageLayout } from 'components/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { PasswordField } from '@clariness/password-field';
import { Schemas, useFormValidation } from 'utils/validation';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import { Services } from 'services';
import { useRegisterContext } from 'pages';
import { useMultiStep } from 'components/MultiStep';
import { TextField } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';

export const RegisterForm = () => {
  const { token, requestEmail, setEmail, setPassword } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormValidation();

  const intl = useIntl();

  const { nextStep } = useMultiStep();

  const { checkIfCanRegister, isLoading } =
    Services.SiteEmployees.useCanRegister({
      onSuccess: () => {
        setEmail(getValues('email'));
        setPassword(getValues('password'));
        nextStep();
      },
    });

  const {
    sendInvitationLinkEmail,
    isLoading: isSendInvitationLinkEmailLoading,
    isSuccess: isSendInvitationLinkEmailSuccess,
  } = Services.SiteEmployees.useSendInvitationLinkEmail();

  const { onChange, name } = register('email');

  useEffect(() => {
    setValue('email', requestEmail);
  }, []);

  return (
    <React.Fragment>
      <AuthPageLayout.Logo />

      <AuthPageLayout.Heading>
        <FormattedMessage id="is.register.heading" defaultMessage="Register" />
      </AuthPageLayout.Heading>

      <AuthPageLayout.Form
        onSubmit={handleSubmit((data) =>
          checkIfCanRegister({ token, email: data.email })
        )}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <TextField
            requiredIndicator={true}
            {...register('email', Schemas.email(intl))}
            value={watch('email') || requestEmail}
            onChange={(value) => {
              onChange({ target: { name, value } });
            }}
            error={errors?.email?.message}
            type="email"
            data-test-id="register-email-input"
            autoComplete="on"
            label="email"
            labelHidden
            placeholder="Email"
          />
        </Box>

        <PasswordField
          required
          placeholder={intl.formatMessage({
            id: 'register.password.placeholder',
            defaultMessage: 'Set Password',
          })}
          {...register('password', Schemas.password(intl))}
          error={errors?.password?.message}
          data-test-id="register-password-input"
        />

        <Button
          type="submit"
          loading={isLoading}
          data-test-id="register-submit-button"
        >
          <FormattedMessage id="is.register.button" defaultMessage="Save" />
        </Button>

        <Link to={Routing.LOGIN.getPath()}>
          <FormattedMessage
            id="is.register.already_registered_link"
            defaultMessage="Already registered? Login here"
          />
        </Link>

        {token && (
          <Button
            variant="tertiary"
            type="button"
            disabled={isSendInvitationLinkEmailSuccess}
            loading={isSendInvitationLinkEmailLoading}
            onClick={() => sendInvitationLinkEmail(token)}
            data-test-id="register-send-invitation-button"
          >
            <FormattedMessage
              id="is.register.send_new_invitation_link"
              defaultMessage="Send me a new invitation link"
            />
          </Button>
        )}
      </AuthPageLayout.Form>

      <AuthPageLayout.InfoLinks />
    </React.Fragment>
  );
};
