import { useState, useEffect } from 'react';
import { Dropdown } from '@clariness/dropdown';
import { DatePickerField } from '@clariness/date-picker-field';
import { ThemeUIStyleObject } from 'theme-ui';
import { Button } from '@clariness/button';
import { CheckCircleIcon, CrossCrossIcon } from '@clariness/clarikit-icons';
import { Icon } from '@clariness/clarikit-react';
import { formatDate, FormatStatus } from 'utils/format';
import { ShouldRender } from 'components/ShouldRender';
import { Box } from '@clariness/box';
import { ReferralDto, Protocol801ParticipationUpdateDto } from 'common/api';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/clarikit-react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  referral?: ReferralDto;
  Protocol801Participation: (value: any) => void;
  refetch: () => void;
}

export const Protocol801 = ({
  referral,
  Protocol801Participation,
  refetch,
}: Props) => {
  const intl = useIntl();

  const [isProtocol801Edit, setIsProtocol801Edit] = useState<boolean>(false);
  const [date, setDate] = useState<Date>();
  const [outcome, setOutcome] = useState<
    Protocol801ParticipationUpdateDto.outcome | undefined
  >();

  const datepickerStyle: ThemeUIStyleObject = {
    '.react-datepicker__input-container > div': { gap: 0, padding: 0 },
    '.react-datepicker-popper': {
      zIndex: 100,
      background: 'var(--theme-ui-colors-hgl7)',
    },
    '.react-datepicker__input-container > div > span:nth-of-type(2)': {
      margin: '0 !important',
    },
    '.react-datepicker__input-container > div > span:nth-of-type(2) > input': {
      borderColor: 'var(--theme-ui-colors-text4)',
    },
  };

  useEffect(() => {
    if (
      referral?.protocol801Participation.date &&
      referral.protocol801Participation.outcome
    ) {
      setDate(new Date(referral.protocol801Participation.date));
      setOutcome(referral.protocol801Participation?.outcome);
    }
  }, [referral]);

  const handelSubmit = () => {
    if (outcome && date && referral?.id) {
      const payload: Protocol801ParticipationUpdateDto = {
        outcome,
        date: date.toISOString(),
        referralId: referral.id,
      };
      Protocol801Participation(payload);
      setIsProtocol801Edit(false);
      refetch();
    }
  };

  return (
    <>
      <ShouldRender
        when={Boolean(
          (!referral?.protocol801Participation.date &&
            !referral?.protocol801Participation.outcome) ||
            isProtocol801Edit
        )}
      >
        <Box>
          <Dropdown
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.protocol_801.outcome',
              defaultMessage: 'Outcome',
            })}
            onChange={(e) => {
              const target = e.target as HTMLSelectElement;
              setOutcome(
                target.value as Protocol801ParticipationUpdateDto.outcome
              );
            }}
            defaultValue={outcome}
          >
            <Dropdown.Option value="">
              {intl.formatMessage({
                id: 'is.main_page.referrals_table_label.select',
                defaultMessage: 'Select',
              })}
            </Dropdown.Option>
            {Object.keys(Protocol801ParticipationUpdateDto.outcome).map(
              (key) => {
                return (
                  <Dropdown.Option key={key} value={key}>
                    {FormatStatus(
                      key as Protocol801ParticipationUpdateDto.outcome
                    )}
                  </Dropdown.Option>
                );
              }
            )}
          </Dropdown>
        </Box>
        <Box
          sx={{
            ...datepickerStyle,
            marginBottom: '5px',
          }}
        >
          <Text as="p">
            <FormattedMessage
              id="is.update_patient_status.date_picker_label"
              defaultMessage="Date"
            />
          </Text>
          <DatePickerField
            position="bottom left"
            placeholder={intl.formatMessage({
              id: 'is.update_patient_status.date_picker_placeholder',
              defaultMessage: 'Select date',
            })}
            selected={date}
            dateFormat={formatDate(intl.formatDate(date))}
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </Box>
        <Flex
          sx={{
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: '15px',
          }}
        >
          <Button variant="tertiary" size="medium" onClick={handelSubmit}>
            <FormattedMessage id="is.add" defaultMessage="Add" />
          </Button>

          <ShouldRender
            when={Boolean(
              referral?.protocol801Participation?.date &&
                referral?.protocol801Participation?.outcome
            )}
          >
            <Button
              variant="tertiary"
              size="medium"
              color="text3"
              onClick={() => setIsProtocol801Edit(false)}
            >
              <FormattedMessage
                id="is.update_protocol_modal.cancel_button"
                defaultMessage="Cancel"
              />
            </Button>
          </ShouldRender>
        </Flex>
      </ShouldRender>
      <ShouldRender
        when={Boolean(
          referral?.protocol801Participation?.date &&
            referral?.protocol801Participation?.outcome &&
            !isProtocol801Edit
        )}
      >
        <Flex gap={3}>
          <Box>
            {referral?.protocol801Participation?.outcome !==
            Protocol801ParticipationUpdateDto.outcome.CONSENTED_RANDOMIZED ? (
              <Icon source={CheckCircleIcon} color="color-green-quaternary" />
            ) : (
              <Icon source={CrossCrossIcon} color="color-gray-variant-2" />
            )}
          </Box>
          <Flex
            sx={{
              gap: '3',
              flexDirection: 'column',
            }}
          >
            <Text as="p" variant='bodyMd'>
              {FormatStatus(
                referral?.protocol801Participation
                  ?.outcome as Protocol801ParticipationUpdateDto.outcome
              )}
            </Text>
            <Text as="p" variant='bodyMd'>
              {date?.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </Text>
          </Flex>

          <Button
            variant="tertiary"
            size="medium"
            onClick={() => setIsProtocol801Edit(true)}
          >
            <FormattedMessage id="is.edit" defaultMessage="Edit" />
          </Button>
        </Flex>
      </ShouldRender>
    </>
  );
};
