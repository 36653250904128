import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { getInvestigatorSitesApi } from './api';

const GET_INVESTIGATOR_SITES = '@sites/by-investigator';

export function useGetInvestigatorSites(id: string) {
  const { showNotification } = Store.useNotifications();
  const intl = useIntl();

  const { data, isLoading, isError } = ReactQuery.useQuery(
    GET_INVESTIGATOR_SITES,
    () => getInvestigatorSitesApi(id),
    {
      onError: () => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.get_sites_error',
            defaultMessage:
              'Unfortunately, something failed when fetching your sites. Please, try again later.',
          }),
        });
      },
    }
  );
  return {
    sites: data,
    isLoading,
    isError,
  };
}
