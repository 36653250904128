import React from 'react';
import { Text } from '@clariness/clarikit-react';
import { FormattedMessage } from 'react-intl';

export const AuthPageLayoutFooter: React.FC = () => {
  return (
    <Text as="p" variant="bodySm">
      <FormattedMessage
        id="is.footer.text"
        defaultMessage="Copyright CLARINESS © 2021. All rights reserved."
      />
    </Text>
  );
};
