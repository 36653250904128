import React, { ReactElement } from 'react';
import { Flex } from '@clariness/flex';
import { StyleObject } from '@clariness/base';
import { Text } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';
export type NavBarElementProps = {
  sx?: StyleObject;
  icon: ReactElement;
};

export const NavBarElement: React.FC<NavBarElementProps> = ({
  sx,
  icon,
  children,
}) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '5px',
        color: 'text-primary',
        ...sx,
      }}
    >
      {icon}

      <Box sx={{ color: 'inherit' }}>
        <Text as="p" >{children}</Text>
      </Box>
    </Flex>
  );
};
