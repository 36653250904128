import React from 'react';
import * as ReactIntl from 'react-intl';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/clarikit-react';
import { RadioButton } from '@clariness/radio-button';
import { FormattedMessage } from 'react-intl';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';

export const NewAppointmentNeeded: React.FC = () => {
  const intl = ReactIntl.useIntl();

  const { setCanNextStep, setIsNewAppointmentNeeded } =
    useUpdatePatientStatusContext();

  return (
    <UpdateStatusLayout
      heading={intl.formatMessage({
        id: 'is.referral_status.reschedule_appointment_question',
        defaultMessage: 'Would you like to reschedule the patient appointment?',
      })}
    >
      <Flex
        gap={3}
        sx={{
          alignItems: 'center',
          height: '30px',
        }}
      >
        <RadioButton
          name="isNewAppointmentNeeded"
          id="isNewAppointmentNeeded"
          value="isNewAppointmentNeeded"
          onClick={() => {
            setIsNewAppointmentNeeded(true);
            setCanNextStep(true);
          }}
        />
        <Text as="p">
          <FormattedMessage id="is.yes" defaultMessage="Yes" />
        </Text>
      </Flex>
      <Flex
        gap={3}
        sx={{
          alignItems: 'center',
          height: '30px',
        }}
      >
        <RadioButton
          name="isNewAppointmentNeeded"
          id="isNewAppointmentNeeded"
          value="isNewAppointmentNeeded"
          onClick={() => {
            setIsNewAppointmentNeeded(false);
            setCanNextStep(true);
          }}
        />
        <Text as="p">
          <FormattedMessage id="is.no" defaultMessage="No" />
        </Text>
      </Flex>
    </UpdateStatusLayout>
  );
};
