import React, { useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { Grid } from '@clariness/grid';
import { TextField } from '@clariness/clarikit-react';
import { Dropdown } from '@clariness/dropdown';
import { Text } from '@clariness/clarikit-react';
import { Store } from 'store';
import { useMultiStep } from 'components/MultiStep';
import * as _ from 'lodash-es';
import { SiteEmployeeTitle } from 'global/siteEmployeeTitle';
import { Services } from 'services';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Box } from '@clariness/box';

export const AccountDetailsForm = () => {
  const intl = useIntl();

  const { nextStep } = useMultiStep();
  const { user } = Store.useUser();

  const [formValues, setFormValues] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
  });

  const [title, setTitle] = useState({
    label: user?.title ? SiteEmployeeTitle[user.title].label : '',
    value: user?.title,
  });

  const { updateCurrentSiteEmployee, isLoading } =
    Services.SiteEmployees.useUpdateCurrentSiteEmployee({
      onSuccess: (data) => {
        captureEventV2(
          Events.InvestigatorService.InvestigatorChangeAccountDetails({
            InvestigatorID: user?.id ?? '',
          })
        );
        setFormValues({
          ...formValues,
          ...data,
        });
      },
    });

  const validate = () => {
    if (formValues.firstName && formValues.lastName) {
      return true;
    }
    return false;
  };

  const handleChange = (value: string, name: string) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (): void => {
    if (validate()) {
      updateCurrentSiteEmployee({ ...formValues, title: title.value });
    }
  };

  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_details.heading"
          defaultMessage="Account details"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Paragraph>
        <FormattedMessage
          id="is.account_details.main_paragraph"
          defaultMessage="You can update your personal data and change your password here."
        />
      </AccountPageLayout.Paragraph>

      <AccountPageLayout.Form onSubmit={handleSubmit}>
        <Grid
          sx={{
            columnGap: '54px',
            rowGap: '34px',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Dropdown
            defaultValue={title.value}
            onChange={(e) => {
              const target = e.target as HTMLSelectElement;
              setTitle({ label: target.value, value: target.value as any });
            }}
            placeholder={intl.formatMessage({
              id: 'is.account_details.title_select_placeholder',
              defaultMessage: 'Title',
            })}
            data-test-id="account-title-select"
          >
            {_.map(SiteEmployeeTitle, (siteEmployeeTitle) => (
              <Dropdown.Option
                key={siteEmployeeTitle.value}
                value={siteEmployeeTitle.value}
              >
                {siteEmployeeTitle.label}
              </Dropdown.Option>
            ))}
          </Dropdown>

          <TextField
            label="First name"
            requiredIndicator={true}
            labelHidden
            name="firstName"
            value={formValues.firstName}
            onChange={(val) => handleChange(val, 'firstName')}
            error={
              !formValues.firstName &&
              intl.formatMessage({
                id: 'is.input.field_required',
                defaultMessage: 'This field is mandatory',
              })
            }
            placeholder={intl.formatMessage({
              id: 'is.account_details.first_name_input_placeholder',
              defaultMessage: 'First name',
            })}
            data-test-id="account-first-name-input"
            autoComplete="on"
          />

          <TextField
            label="Last name"
            requiredIndicator={true}
            labelHidden
            name="lastName"
            value={formValues.lastName}
            onChange={(val) => handleChange(val, 'lastName')}
            error={
              !formValues.lastName &&
              intl.formatMessage({
                id: 'is.input.field_required',
                defaultMessage: 'This field is mandatory',
              })
            }
            placeholder={intl.formatMessage({
              id: 'is.account_details.last_name_input_placeholder',
              defaultMessage: 'Last name',
            })}
            data-test-id="account-last-name-input"
            autoComplete="on"
            type="text"
          />

          <TextField
            label="Email address"
            name="email"
            labelHidden
            value={formValues.email}
            disabled
            placeholder={intl.formatMessage({
              id: 'is.account_details.email_input_placeholder',
              defaultMessage: 'Email address',
            })}
            data-test-id="account-email-input"
            type="email"
            autoComplete="off"
          />

          <Box>
            <TextField
              placeholder="****************"
              disabled
              data-test-id="account-password-input"
              autoComplete="off"
              label="password"
              labelHidden
            />

            <Button
              variant="tertiary"
              size="medium"
              onClick={nextStep}
              data-test-id="account-change-password-button"
              marginTop="16px"
            >
              <FormattedMessage
                id="is.account_details.change_password_button"
                defaultMessage="Change password"
              />
            </Button>
          </Box>

          <TextField
            label="Phone number"
            labelHidden
            name="phone"
            value={formValues.phone}
            onChange={(val) => handleChange(val, 'phone')}
            placeholder={intl.formatMessage({
              id: 'is.account_details.phone_number_input_placeholder',
              defaultMessage: 'Phone number',
            })}
            data-test-id="account-phone-input"
            autoComplete="off"
            type="tel"
          />
        </Grid>

        <Flex
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Text as="p" variant="bodySm">
            <FormattedMessage
              id="is.account_details.mandatory_fields_text"
              defaultMessage="*Mandatory fields"
            />
          </Text>

          <Button
            type="submit"
            disabled={!validate()}
            loading={isLoading}
            data-test-id="account-details-save-button"
          >
            <FormattedMessage
              id="is.account_details.save_button"
              defaultMessage="Save"
            />
          </Button>
        </Flex>
      </AccountPageLayout.Form>
    </AccountPageLayout>
  );
};
