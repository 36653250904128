import { Auth } from './auth';
import { Referrals } from './referrals';
import { Translations } from './translations';
import { SiteEmployees } from './site-employees';
import { Studies } from './studies';
import { ProductUpdates } from './product-updates';
import { StudySitePerformance } from './study-site-performance';

import { InvestigatorSites } from './sites';
export * from './ServiceProvider';
export * from './types';

export const Services = {
  Auth,
  Referrals,
  SiteEmployees,
  Studies,
  Translations,
  ProductUpdates,
  StudySitePerformance,
  InvestigatorSites,
};
