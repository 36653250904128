import React, { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/clarikit-react';

type LabeledRowProps = {
  label: string | ReactNode;
};

export const LabeledRow = ({
  label,
  children,
}: PropsWithChildren<LabeledRowProps>) => {
  return (
    <Box as="tr">
      <Flex
        as="th"
        sx={{
          textAlign: 'left',
          pb: '15px',
          width: '150px',
          minWidth: '150px',
          flexDirection: 'row',
        }}
      >
        <Text as="p" variant="bodyMd" fontWeight="bold">{label}</Text>
      </Flex>

      <Box as="td" sx={{ pl: '40px', pb: '15px' }}>
        <Text as="p" variant="bodyMd">{children}</Text>
      </Box>
    </Box>
  );
};
