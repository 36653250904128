import React, { useEffect, useState } from 'react';
import { AuthPageLayout } from 'components/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Schemas, useFormValidation } from 'utils/validation';
import { Services } from 'services';
import { Button } from '@clariness/button';
import { useLocation } from 'react-router-dom';
import { TextField } from '@clariness/clarikit-react';
import { Box } from '@clariness/box';

const EXPIRED_SEARCH = '?expired=true';
export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormValidation();

  const [passwordExpired, setPasswordExpired] = useState(false);

  const intl = useIntl();
  const location = useLocation();

  const { onChange, name } = register('email');

  useEffect(() => {
    if (location?.search === EXPIRED_SEARCH) {
      setPasswordExpired(true);
    }
  }, [location]);

  const { sendForgotPasswordEmail, isLoading } =
    Services.SiteEmployees.useSendForgotPasswordEmail();

  return (
    <AuthPageLayout>
      <AuthPageLayout.Logo />

      <AuthPageLayout.Heading>
        {passwordExpired ? (
          <FormattedMessage
            id="is.forgot_password.password_expired"
            defaultMessage="Password Expired"
          />
        ) : (
          <FormattedMessage
            id="is.forgot_password.heading"
            defaultMessage="Forgotten Password"
          />
        )}
      </AuthPageLayout.Heading>

      <AuthPageLayout.Paragraph>
        {passwordExpired ? (
          <FormattedMessage
            id="is.forgot_password.expired_password_info"
            defaultMessage="Your password has expired and must be changed."
          />
        ) : (
          <FormattedMessage
            id="is.forgot_password.main_paragraph"
            defaultMessage="Confirm the email address associated with your account, and we'll send you a link to reset your password"
          />
        )}
      </AuthPageLayout.Paragraph>

      <AuthPageLayout.Form
        onSubmit={handleSubmit(({ email }) => sendForgotPasswordEmail(email))}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <TextField
            {...register('email', Schemas.email(intl))}
            placeholder={intl.formatMessage({
              id: 'is.forgot_password.email_input_placeholder',
              defaultMessage: 'Email',
            })}
            value={watch('email')}
            onChange={(value) => {
              onChange({ target: { name, value } });
            }}
            error={errors?.email?.message}
            data-test-id="IS-forgot-password-email-input"
            autoComplete="on"
            label='email'
            labelHidden
          />
        </Box>

        <Button
          type="submit"
          loading={isLoading}
          data-test-id="IS-forgot-password-send-link-button"
        >
          <FormattedMessage
            id="is.forgot_password.send_button"
            defaultMessage="Send link"
          />
        </Button>
      </AuthPageLayout.Form>

      <AuthPageLayout.InfoLinks />
      <AuthPageLayout.Footer />
    </AuthPageLayout>
  );
};
