import React from 'react';
import { GlobalProvider } from 'app/GlobalProvider';
import { Routes } from 'app/Routes';
import { ModalContainer } from 'components/modals';
import { Notifications } from 'app/Notifications';
import { posthog } from 'posthog-js';
import { Environment } from 'global/environment';
import { useEventTracking } from 'utils/events';
import { SocketProvider } from 'utils/websocket/WebsocketContext';
import { ThemeProvider } from '@clariness/clarikit-react';
import '@clariness/clarikit-react/styles.css';
import '@clariness/clarikit-tokens/css/styles.css';

const App: React.FC = () => {
  // init posthog, don't capture events when developing
  if (
    !(
      window.location.href.includes('127.0.0.1') ||
      window.location.href.includes('localhost')
    )
  ) {
    posthog.init(`${Environment.POSTHOG_TOKEN}`, {
      api_host: `${Environment.POSTHOG_HOST}`,
      autocapture: false,
      opt_in_site_apps: true,
      person_profiles: 'identified_only',
    });
    posthog.opt_out_capturing(); // opt out of tracking until user gives Cookiebot consent
  }

  useEventTracking();

  return (
    <ThemeProvider>
      <SocketProvider>
        <GlobalProvider>
          <Routes />
          <ModalContainer />
          <Notifications />
        </GlobalProvider>
      </SocketProvider>
    </ThemeProvider>
  );
};

export default App;
